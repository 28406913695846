/* 
*  CSS TABLE OF CONTENTS
*  
*  1.0 - Reset
*  2.0 - Fonts
*  3.0 - Color Palette
*  4.0 - Globals
*  5.0 - Loader
*  6.0 - Header
*  7.0 - Body
*    6.1 - Sliders
*    6.2 - Imagery
*
*
* BREAKPOINTS
*
* 320px — 480px: Mobile devices
* 481px — 768px: iPads, Tablets
* 769px — 1024px: Small screens, laptops
* 1025px — 1200px: Desktops, large screens
* 1201px and more —  Extra large screens, TV
*/

/*** 1.0 - Reset ***/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


/*** 2.0 - Fonts ***/

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500&display=swap');

/*** 3.0 - Color Palette ***/

:root {
  --color-primary: #E42321;
  --color-secondary: #038DAF;
  --color-white: #FFFFFF;
  --color-grey: #555555;
  --color-black: #000000;
  --color-background: #F3F3F3;
}

/*** 4.0 - Globals ***/

body {
  font-family: 'Barlow', sans-serif;
  background-color: var(--color-background);
}


/*** 5.0 - Loader ***/

.preloader {
  background: var(--color-white);
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.loading {
    overflow: hidden;
    position: absolute;
}

.loading img{
  max-width: 10rem;
  margin-bottom: 1rem;
}

.progress {
    display: block;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    width: 0%;
    height: 5px;
    background-image: linear-gradient(0, var(--color-primary) 0%, var(--color-primary) 100%);
    animation-fill-mode: forwards;
    animation: loader-animation 1s cubic-bezier(.75, .25, .25, .75);
}

@keyframes loader-animation {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

/*HEADER*/
.header {
  background-color: white;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
  border-bottom: solid 5px #E42321;
}

/*** 6.2 - Logo ***/

.logo {
  height: 52px;
}

.logo-min {
  display: none;
}

@media (max-width: 480px) {
  .logo {
    display: none;
  }
  
  .logo-min {
    display: inline;
  }
}

.menu {
  list-style: none;
  display: flex;
}

.menu-item {
  padding: 0px 32px;
}

.menu-link {
  color: #555555;
  font-size: 22.5px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
}

.button {
  cursor: pointer;
  padding: 14px 32px;
  border: none;
  color: white;
  font-size: 21px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  background-color: #E42321;
  display: inline-block;
}

@media (max-width: 480px) {
  .menu {
    display: none;
  }
}

.nav-link:hover {
  color: #E42321;
}

.active {
  border-bottom: solid 3px #E42321;
}


/*MAIN*/
.main {
  padding: 0px 32px;
}

@media (max-width: 1024px) {
  .main {
    padding: 0px 16px;
  }
}

.grid {
  padding: 32px 0px;
  width: auto;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
}

@media (max-width: 1024px) {
  .grid {
      grid-template-columns: repeat(1, 1fr);
      padding: 16px 0px;
      grid-gap: 16px;
  }
}

/* Card*/
.card {
  padding: 16px;
  background-color: white;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
  transition: transform .3s ease-in-out;
}

@media (max-width: 1024px) {
  .card {
    padding: 8px;
  }
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 21px;
  color: black;
  min-height: 2.6em;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.08rem;
}

.card-chart {
  overflow: hidden;
}

.chart-area {
  height: 440px;
  width: 100%;
}

@media (max-width: 1024px) {
  .chart-area {
    height: 220px;
    width: 100%;
  }
}

.switchDataset {
  cursor: pointer;
  padding: 14px 32px;
  border: none;
  color: white;
  font-size: 21px;
  text-decoration: none;
  font-weight: 500;
  background-color: #E42321;
  display: inline-block;
}

.red {
  color:#E42321;
}

h1 {
  font-size: 68px;
  font-weight: 800;
}

.select-left {
  background-color: #E42321;
  padding: 8px  16px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  border: none;
}

.select-right {
  background-color: #038DAF;
  padding: 8px  16px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  border: none;
}

.select-right-m {
  margin: 0px 16px;
  background-color: #038DAF;
  padding: 8px  16px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  border: none;
}

p {
  color: #555555;
  font-size: 21px;
  text-decoration: none;
  font-weight: 700;
}

.singlechart-container {
  display: flex;
  justify-content: center;
  padding-top: 32px;
}

.singlechart {
  width: 50%;
}

/*Home Main*/

.button-list {
  padding-top: 21px;
  list-style: none;
}

.button-item {
  display: inline-block;
  margin: 14px 32px;
  padding: 14px 32px;
  background-color: #E42321;
}

.button-link {
  color: white;
  font-size: 21px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
}

.button-link:hover {
  color: white;
}

.hero {
  padding-top: 68px;
  padding-bottom: 21px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

select {
  color: #555555;
  border: none;
  text-align: end;
}

select:focus {
  outline: none;
}

.select-box {
  display: flex;
  flex-direction: column;
  justify-content: end;
  
}

.year {
  text-align: end;
}
